.splitSection {
  width: 100%;
  padding: 80px 0;
}

.splitSection__container {
  padding-top: 0;
  padding-bottom: 0;
}

.splitSection__inner {
  align-items: center;
  flex-direction: row;
}

.flipped {
  flex-direction: row-reverse;
}

.imageWrapper {
  /* margin-right: 80px; */
  width: 100%;
}

.imageWrapper img {
  width: 100%;
}

.imageWrapper.splitRowReverse {
  margin-left: 80px;
}

.imageWrapper.row {
  margin-right: 80px;
}

.contentWrapper {
  width: 50%;
  flex-shrink: 0;
}

.heading {
  color: white;
  background: black;
  padding: 5px 20px;
  width: auto;
  display: inline-block;
  font-size: 20px;
  /* font-weight:  */
}

@media only screen and (max-width: 1024px) {
  .splitSection {
    padding: 40px 0;
  }

  .splitSection__inner {
    flex-direction: column;
  }
  .imageWrapper.splitRowReverse {
    margin-left: 0;
  }

  .imageWrapper.row {
    margin-right: 0;
  }

  .imageWrapper {
    margin-bottom: 30px;
  }

  .contentWrapper {
    width: 100%;
  }
}
